






















































































































































































































import { Component, Vue } from "vue-property-decorator";
import RequestHandler from "@/assets/ts/requestHandler.ts";
import { NewEmployee } from "@/types";

const RH = new RequestHandler();

@Component
export default class EmployeeManagement extends Vue {
  private employees = [];
  private filteredEmployees = [];
  private addEmployeeDialog = false;
  private showPassword = false;
  private accountSearchText = "";
  private employerMVDSCustomerID = 0;
  private accountTableHeaders = [
    {
      text: "Employee Name",
      value: "fullname"
    },
    {
      text: "Status",
      value: ""
    },
    {
      text: "Actions",
      value: "actions"
    }
  ];
  private newEmployee = {} as NewEmployee;
  private newEmployeeInfoValid = false;

  private statusFilter = "All";
  private statusBoxItems = [
    "All",
    "Active",
    "Inactive",
    "Deactivated by Admin",
    "Removed"
  ];

  get newEmployeeForm(): Vue & { validate: () => boolean } {
    return this.$refs.newEmployeeForm as Vue & { validate: () => boolean };
  }

  private rules = {
    required: (value: string | number) => !!value || "This field is required.",
    password: (value: string) => {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_+={[}\]|\\:;"'<,>.?/-])[A-Za-z\d~`!@#$%^&*()_+={[}\]|\\:;"'<,>.?/-]{8,}$/;
      return (
        pattern.test(value) ||
        "Must contain one lowercase letter, one uppercase letter, one number, one special character, and be at least eight characters in total"
      );
    },
    email: (value: string) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail address.";
    }
  };

  private async updateEmployeeStatus(
    employee: { [key: string]: any },
    statusCode: string
  ) {
    try {
      await RH.handleEmployee(employee["user"]["id"], statusCode);
      this.$root.$emit(
        "snackbar-message",
        "Successfully altered employee named " +
          employee["user"]["fullname"] +
          "."
      );
      this.setEmployees();
    } catch (e) {
      this.$root.$emit(
        "snackbar-message",
        "Error altering employee named " + employee["user"]["fullname"] + "."
      );
    }
  }

  private async addEmployee() {
    if (this.newEmployeeForm.validate()) {
      try {
        await RH.addNewEmployee(this.newEmployee, this.employerMVDSCustomerID);
        this.$root.$emit(
          "snackbar-message",
          "Successfully added employee named " + this.newEmployee.username + "."
        );
      } catch (e) {
        this.$root.$emit(
          "snackbar-message",
          "Error adding employee named " + this.newEmployee.username + "."
        );
      } finally {
        this.newEmployee = {} as NewEmployee;
        this.addEmployeeDialog = false;
        this.setEmployees();
      }
    }
  }

  private async setEmployees(): Promise<void> {
    const userID = this.$store.getters.userInfo.userID;
    try {
      this.$root.$emit("start-loading");
      this.employerMVDSCustomerID = (
        await RH.getUserCustomerInfo(userID)
      ).mvdsCustomerID;
      this.employees = await RH.getEmployees(this.employerMVDSCustomerID);
      this.employees = this.employees.filter(
        employee => employee["user"]["id"] != userID
      );
      this.employees.forEach((employee: any) => {
        const statusMap = new Map([
          ["A", "Active"],
          ["D", "Deactivated by Admin"],
          ["I", "Inactive"],
          ["R", "Removed"]
        ]);
        const statusCode = employee["statusCode"];
        employee.status = statusMap.has(statusCode)
          ? statusMap.get(statusCode)
          : "N/A";
      });
      this.filteredEmployees = this.employees;
    } catch (e) {
      console.error(e);
    } finally {
      this.$root.$emit("stop-loading");
    }
  }

  private filterEmployees() {
    switch (this.statusFilter) {
      case "Active":
        this.filteredEmployees = this.employees.filter(
          employee => employee["statusCode"] === "A"
        );
        break;
      case "Inactive":
        this.filteredEmployees = this.employees.filter(
          employee => employee["statusCode"] === "I"
        );
        break;
      case "Deactivated by Admin":
        this.filteredEmployees = this.employees.filter(
          employee => employee["statusCode"] === "D"
        );
        break;
      case "Removed":
        this.filteredEmployees = this.employees.filter(
          employee => employee["statusCode"] === "R"
        );
        break;
      default:
        this.filteredEmployees = this.employees;
        break;
    }
  }

  async created() {
    await this.setEmployees();
  }
}
